/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { useState } from "react";
import { Button, Card, Modal } from "react-bootstrap";
import MtrApiLogs from "./MtrApiLogs";
import TcApiLogs from "./TcApiLogs";
import Tc2ApiLogs from "./Tc2ApiLogs";
import LhApiLogs from "./LhApiLogs";

export default function ApiLog() {
    const [modalSwitch, setModalSwitch] = useState('');

    return (
        <>
            <Card>
                <Card.Header>
                    <Card.Title>API Logs</Card.Title>
                </Card.Header>
                <Card.Body style={{display: 'flex', gap: 12, justifyContent: 'flex-end'}}>
                    <Button onClick={()=>setModalSwitch('tc2')}>
                        View TC 2.0 Logs
                    </Button>
                    <Button onClick={()=>setModalSwitch('tc')}>
                        View TC Logs
                    </Button>
                    <Button onClick={()=>setModalSwitch('mtr')}>
                        View MTR Logs
                    </Button>
                    <Button onClick={()=>setModalSwitch('lh')}>
                        View LH Logs
                    </Button>
                    
                </Card.Body>
            </Card>
            <Modal show={modalSwitch == 'tc2'} onHide={()=>setModalSwitch('')} size="xl">
                <Tc2ApiLogs/>
            </Modal>
            <Modal show={modalSwitch == 'tc'} onHide={()=>setModalSwitch('')} size="xl">
                <TcApiLogs/>
            </Modal>
            <Modal show={modalSwitch == 'mtr'} onHide={()=>setModalSwitch('')} size="xl">
                <MtrApiLogs/>
            </Modal>
            <Modal show={modalSwitch == 'lh'} onHide={()=>setModalSwitch('')} size="xl">
                <LhApiLogs/>
            </Modal>
        </>
    )
}

