/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faPlus } from "@fortawesome/free-solid-svg-icons";
import PayModal from "../PayModal/PayModal";
import PayrollEmployeeNondiscretionaryBonuses from "./PayrollEmployeeNondiscretionaryBonuses";
import PayrollEmployeeWagesEditor from "./PayrollEmployeeWagesEditor";
import PayrollEmployeeAdditionalPayEditor from "./PayrollEmployeeAdditionalPayEditor";
import PayrollEmployeePtoEditor from "./PayrollEmployeePtoEditor";
import PayrollEmployeeHolidaysEditor from "./PayrollEmployeeHolidaysEditor";
import PayrollEmployeeDiscretionaryBonuses from "./PayrollEmployeeDiscretionaryBonuses";
import { Dropdown, OverlayTrigger, Popover } from "react-bootstrap";
import moment from "moment";
import { closestDateBeforeOrOnDayIndex } from "../../../../payrollTools";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { toFixedMax } from "../../../../payrollTools";

export default function PayrollEmployeePayEditor({entry, index = 0, handleSet, handleSetWeek, handleAddBonusToAllEntries, selectedPayPeriod, payrollPeriodStartDay}) {
    const [showModal, setShowModal] = useState(false);
    const [weekIndex, setWeekIndex] = useState(0);

    const handleAddAdditionalPay = (additionalPay, applyToAll) => {
        if (applyToAll) {
            handleAddBonusToAllEntries('additionalPay', additionalPay, weekIndex);
        } else {
            const newArr = Array.from(entry.weeks[weekIndex].additionalPay);
            newArr.push(additionalPay);
            handleSetWeek(weekIndex, 'additionalPay', newArr);
        }
        setShowModal(false);
    }

    const handleAddNonDiscretionaryBonus = (ndBonus, applyToAll) => {
        if (applyToAll) {
            handleAddBonusToAllEntries('ndBonuses', ndBonus, weekIndex);
        } else {
            const newArr = Array.from(entry.weeks[weekIndex].ndBonuses);
            newArr.push(ndBonus);
            handleSetWeek(weekIndex, 'ndBonuses', newArr);
        }
        setShowModal(false);
    }

    const handleAddDiscretionaryBonus = (dBonus, applyToAll) => {
        if (applyToAll) {
            handleAddBonusToAllEntries('dBonuses', dBonus, weekIndex);
        } else {
            const newArr = Array.from(entry.weeks[weekIndex].dBonuses);
            newArr.push(dBonus);
            handleSetWeek(weekIndex, 'dBonuses', newArr);
        }
        setShowModal(false);
    }

    const handleAddHoliday = (holiday) => {
        const newArr = Array.from(entry.weeks[weekIndex].holidays);
        newArr.push(holiday);
        handleSetWeek(weekIndex, 'holidays', newArr);
        setShowModal(false);
    }

    const weekDropdownElements = entry.weeks.map((week, index) => {
        const trueWeekStart = moment(closestDateBeforeOrOnDayIndex(entry.periodStart, payrollPeriodStartDay)).add(index, 'weeks').format('YYYY-MM-DD');
        const trueWeekEnd = moment(trueWeekStart).add(6, 'days').format('YYYY-MM-DD');
        const weekStart = trueWeekStart < entry.periodStart ? entry.periodStart : trueWeekStart;
        const weekEnd = trueWeekEnd > entry.periodEnd ? entry.periodEnd : trueWeekEnd;

        return (
            <Dropdown.Item key={index} onClick={() => {setWeekIndex(index); setShowModal(true);}}>
                {`Week #${index + 1} | ${moment(weekStart).format('MMM D')} - ${moment(weekEnd).format('MMM D')}`}
            </Dropdown.Item>
        )
    });

    const trueStartOfFirstWeek = moment(closestDateBeforeOrOnDayIndex(entry.periodStart, payrollPeriodStartDay));
    const trueEndOfFirstWeek = moment(trueStartOfFirstWeek).add(6, 'days');

    return (
        <Card>
            <Card.Body>
                <div style={{display:'flex', flexDirection:'row', alignItems:'center', gap: 12, marginBottom: 8}}>
                    <Card.Title style={{margin: 0}}>Employee Pay</Card.Title>
                    { entry.weeks.length > 1 ?
                        <Dropdown>
                            <Dropdown.Toggle variant='outline-primary' style={{borderRadius: 16, padding: '0px 5px 0px 5px'}}>
                                <FontAwesomeIcon icon={faPlus}/>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {weekDropdownElements}
                            </Dropdown.Menu>
                        </Dropdown>
                        :
                        <Button style={{color: 'white', borderRadius: 14, padding: '0px 5px 0px 5px'}} onClick={() => {setShowModal(true)}}>
                            <FontAwesomeIcon icon={faPlus} style={{color: 'white'}}/>
                        </Button>
                    }
                </div>
                { entry.weeks[0].hasOvertimeWithout40Hours() && 
                    <div style={{flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 4}}>
                        <span style={{fontWeight: 'bold', opacity: 0.5,}}>Overtime without exceeding 40 Hours?</span>
                        <OverlayTrigger overlay={
                            <Popover style={{maxWidth: 600}}>
                                <Popover.Header>Overtime Explanation</Popover.Header>
                                <Popover.Body>
                                    <p style={{marginBottom: 0}}>{`Employee worked ${toFixedMax(entry.weeks[0].trueHoursWorked().toNumber(), 2)} Hours between ${trueStartOfFirstWeek.format('dddd, MMM D')} and ${trueEndOfFirstWeek.format('dddd, MMM D')}.`}</p>
                                    <p style={{marginBottom: 0}}>{`However, ${toFixedMax(entry.weeks[0].beforePeriodHoursWorked, 2)} of those hours occurred before the start of this payroll period.`}</p>
                                </Popover.Body>
                            </Popover>
                        }>
                            <FontAwesomeIcon style={{color: 'var(--bs-primary)', marginLeft: 6}} icon={faQuestionCircle}/>
                        </OverlayTrigger>
                        
                    </div>

                }
                <PayrollEmployeeWagesEditor entry={entry} payrollPeriodStartDay={payrollPeriodStartDay}/>
                { entry.weeks.reduce((prev, curr) => {return prev || curr.ndBonuses.length > 0}, false) &&
                    <PayrollEmployeeNondiscretionaryBonuses handleSetWeek={handleSetWeek} entry={entry} payrollPeriodStartDay={payrollPeriodStartDay}/>
                }
                { entry.weeks.reduce((prev, curr) => {return prev || curr.dBonuses.length > 0}, false) &&
                    <PayrollEmployeeDiscretionaryBonuses handleSetWeek={handleSetWeek} entry={entry} payrollPeriodStartDay={payrollPeriodStartDay}/>
                }
                { entry.weeks.reduce((prev, curr) => {return prev || curr.additionalPay.length > 0}, false) &&
                    <PayrollEmployeeAdditionalPayEditor entry={entry} handleSetWeek={handleSetWeek} payrollPeriodStartDay={payrollPeriodStartDay}/>
                }
                { entry.qualifiesForPtoAndHolidays() && 
                    <>
                        { entry.weeks.reduce((prev, curr) => {return prev || curr.holidays.length > 0}, false) &&
                            <PayrollEmployeeHolidaysEditor entry={entry} handleSetWeek={handleSetWeek} payrollPeriodStartDay={payrollPeriodStartDay}/>
                        }
                        {/* { entry.weeks.reduce((prev, curr) => {return prev || curr.pto.length > 0}, false) &&
                        } */}
                        <PayrollEmployeePtoEditor entry={entry} handleSet={handleSet}/>
                    </>
                }
            </Card.Body>
            <Modal show={showModal} onHide={() => setShowModal(false)} centered size='lg'>
                <PayModal 
                    handleAddAdditionalPay={handleAddAdditionalPay} 
                    handleAddNonDiscretionaryBonus={handleAddNonDiscretionaryBonus}
                    handleAddDiscretionaryBonus={handleAddDiscretionaryBonus}
                    handleAddHoliday={handleAddHoliday} 
                    qualifiesForPtoAndHolidays={() => entry.qualifiesForPtoAndHolidays()}
                />
            </Modal>
        </Card>
    )
}