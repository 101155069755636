/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { useEffect, useState } from "react";
import { getConferenceSignups } from "../../services/AdminToolsService";
import { Button, Card, Col, ListGroup, Modal, Row } from "react-bootstrap";
import QuickTable from "../../components/QuickTable";
import { AppGlobals } from "../../App";
import KeyValueRow from "../../components/KeyValueRow";
import CustomButton from "../../components/CustomButton";
import { addressToString, formatPhoneNumber, usdFormatter } from "../../tools";
import AddressControl from "../../components/AddressControl";
import { Document, Page, PDFViewer, Text, View } from "@react-pdf/renderer";
import SearchBar from "../../components/SearchBar";
import PDFTable, { PDFTableRow } from "../../components/PDFTable";
import LoadingWrapper from "../../components/LoadingWrapper";

export default function ConferenceShowcase({}){
    const [showModal, setShowModal] = useState(false)

    return (
        <>
            <Card>
                <Card.Header>
                    <Card.Title>View Sign-Ups from FedEx Conference</Card.Title>
                </Card.Header>
                <Card.Body>
                    
                </Card.Body>
                <Card.Footer>
                    <Button onClick={()=>setShowModal(true)} style={{display: 'block', marginLeft: 'auto'}}>
                        View
                    </Button>
                </Card.Footer>
            </Card>
            <Modal fullscreen show={showModal} onHide={()=>setShowModal(false)}>
              <ConferenceSignupsTable/>
            </Modal>
        </>
    )
}

function ConferenceSignupsTable({}){
    const [isLoading, setIsLoading] = useState(false);
    const [conferenceSignups, setConferenceSignups] = useState([]);
    const [selectedSignup, setSelectedSignup] = useState(null)
    const [modalSwitch, setModalSwitch] = useState(false)
    const [search, setSearch] = useState('');
    const typeNames = ['Company Name', 'Person Name']
    const typeValue = ['companyName', 'personName']
    const [searchType, setSearchType] = useState('companyName')

    useEffect(() => {
        loadData()
    }, [])

    async function loadData(){
        setIsLoading(true);
        const response = await getConferenceSignups();
        if(response.status == 200){
            setConferenceSignups(response.conferenceSignups);
        }else{
            AppGlobals.alert("danger", response.message);
        }
        setIsLoading(false)
    }

    const plans = {
        0: "Standard",
        1: "Premium",
        2: "Legal Consulting"
    }

    function constructAddress(type){
        if(type === 'businessAddress'){
            return {
                thoroughfare: selectedSignup.businessThoroughfare,
                premise: selectedSignup.businessPremise,
                locality: selectedSignup.businessLocality,
                administrativeArea: selectedSignup.businessAdministrativeArea,
                postalCode: selectedSignup.businessPostalCode
            }
        } else{
            return {
                thoroughfare: selectedSignup.terminalThoroughfare,
                premise: selectedSignup.terminalPremise,
                locality: selectedSignup.terminalLocality,
                administrativeArea: selectedSignup.terminalAdministrativeArea,
                postalCode: selectedSignup.terminalPostalCode
            }
        }
    }

    function hideModal(){
        setModalSwitch('')
    }

    const rows = conferenceSignups.filter((signup) => {
        if(searchType === 'companyName'){
            return signup.companyName.toLocaleLowerCase().includes(search.toLocaleLowerCase())
        } else {
            const firstLast = signup.firstName + " " + signup.lastName
            const lastFirst = signup.lastName + " " + signup.firstName
            return firstLast.toLocaleLowerCase().includes(search.toLocaleLowerCase()) || lastFirst.toLocaleLowerCase().includes(search.toLocaleLowerCase())
        }
    }).map((signup) => {
        return (
            <tr key={signup.uid} style={{cursor: "pointer"}} onClick={() => {setSelectedSignup(signup); setModalSwitch("details")}}>
                <td style={{verticalAlign: "middle"}}>{signup.companyName}</td>
                <td style={{verticalAlign: "middle"}}>{signup.firstName}</td>
                <td style={{verticalAlign: "middle"}}>{signup.lastName}</td>
                <td style={{verticalAlign: "middle"}}>{signup.phoneNumber}</td>
                <td style={{verticalAlign: "middle"}}>{signup.email}</td>
                <td style={{verticalAlign: "middle"}}>{signup.selectedPlan === null ? "Requested More Information" : plans[signup.selectedPlan]}</td>
                <td style={{textAlign: 'center'}}>
                    <Button onClick={(e) => {e.stopPropagation(); setSelectedSignup(signup); setModalSwitch("pdf")}}>Print</Button>
                </td>
            </tr>
        )
    })

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>View Sign-Ups from FedEx Conference</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <LoadingWrapper isLoading={isLoading}>
                    <SearchBar label={"Search by..."} value={search} setValue={setSearch} type={searchType} setType={setSearchType} typeNames={typeNames} typeValues={typeValue}/>
                    <br/>
                    <QuickTable hover rows={rows} headers={["Company Name", "First Name", "Last Name", "Phone Number", "Email", "Plan Selected", ""]}/>
                </LoadingWrapper>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
            <Modal size="xl" centered show={modalSwitch === 'details'} onHide={hideModal}>
                {selectedSignup &&
                    <>
                        <Modal.Header closeButton>
                            <Modal.Title>Viewing {selectedSignup.companyName} Submission</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 24}}>
                            <Card>
                                <Card.Header>
                                    <Card.Title>Pricing/Plan Details</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                <ListGroup>
                                        <KeyValueRow isListItem title="Selected Plan" value={selectedSignup.selectedPlan === null ? "Requested More Information" : plans[selectedSignup.selectedPlan]}/>
                                        {selectedSignup.features?.length > 0 && <KeyValueRow flexWrap={"wrap"} isListItem title="Requested More Info For" value={selectedSignup.features.map(feature => feature.feature).join(", ")}/>}
                                        <KeyValueRow isListItem title="Gross Weekly Payroll" value={usdFormatter.format(selectedSignup.grossWeeklyPayroll)}/>
                                        <KeyValueRow isListItem title="Annual Legal Fees" value={usdFormatter.format(selectedSignup.legalFees)}/>
                                        <KeyValueRow isListItem title="Annual Payroll Cost" value={usdFormatter.format(selectedSignup.payrollCost)}/>
                                        <KeyValueRow isListItem title="Annual Online Employee Management" value={usdFormatter.format(selectedSignup.onlineEmployeeManagement)}/>
                                        {selectedSignup.selectedPlan && <KeyValueRow isListItem title="Annual Budgeting" value={usdFormatter.format(selectedSignup.budgeting)}/>}
                                    </ListGroup>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Header>
                                    <Card.Title>Personal Details</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <ListGroup>
                                        <KeyValueRow isListItem title="First Name" value={selectedSignup.firstName}/>
                                        <KeyValueRow isListItem title="Last Name" value={selectedSignup.lastName}/>
                                        <KeyValueRow isListItem title="Phone Number" value={formatPhoneNumber(selectedSignup.phoneNumber)}/>
                                        <KeyValueRow isListItem title="Email" value={selectedSignup.email}/>
                                    </ListGroup>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Header>
                                    <Card.Title>Business Details</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <ListGroup style={{gap: 24}}>
                                        <KeyValueRow isListItem title="Company Name" value={selectedSignup.companyName}/>
                                        <Row>
                                            <AddressControl title="Business Address" address={constructAddress("businessAddress")} noUid disabled containerBreakPoints={{xl: 12}} lg={6} xl={4} />
                                            <AddressControl title="Terminal Address" address={constructAddress("terminalAddress")} noUid disabled containerBreakPoints={{xl: 12}} lg={6} xl={4} />
                                        </Row>
                                    </ListGroup>
                                </Card.Body>
                            </Card>
                        </Modal.Body>
                    </>
                }
                
            </Modal>
            <Modal fullscreen show={modalSwitch == "pdf"} onHide={hideModal}>
                {selectedSignup &&
                    <>
                        <Modal.Header closeButton>
                            <Modal.Title>Previewing {selectedSignup.companyName} Submission</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <PDFViewer style={{width: '100%', height: '100%'}}>
                                <PDFConferenceSignup selectedSignup={selectedSignup} plans={plans} constructAddress={constructAddress}/>
                            </PDFViewer>
                        </Modal.Body>
                    </>
                }
            </Modal>
        </>
    )
}

function PDFConferenceSignup({selectedSignup, plans, constructAddress}){

    const pricingRows = [
        {label: 'Selected Plan', value: selectedSignup.selectedPlan === null ? "Requested More Information" : plans[selectedSignup.selectedPlan]},
        {label: 'Requested Features', value: selectedSignup.features ? selectedSignup.features.map((feature) => feature.feature).join(", ") : "N/A"},
        {label: 'Gross Weekly Payoll', value: usdFormatter.format(selectedSignup.grossWeeklyPayroll)},
        {label: "Annual Legal Fees", value: usdFormatter.format(selectedSignup.legalFees)},
        {label: "Annual Payroll Cost", value: usdFormatter.format(selectedSignup.payrollCost)},
        {label: "Annual Online Employee Management", value: usdFormatter.format(selectedSignup.onlineEmployeeManagement)},
        {label: "Annual Budgeting", value: usdFormatter.format(selectedSignup.budgeting)},
    ].map((row) => {
        return (
            <PDFTableRow key={row.label} columns={[row.label, row.value]} fontSize={16} padding={4}/>
        )
    })

    const personalRows = [
        {label: 'First Name', value: selectedSignup.firstName},
        {label: 'Last Name', value: selectedSignup.lastName},
        {label: "Phone Number", value: formatPhoneNumber(selectedSignup.phoneNumber)},
        {label: "Email", value: selectedSignup.email},
    ].map((row) => {
        return (
            <PDFTableRow key={row.label} columns={[row.label, row.value]} fontSize={16} padding={4}/>
        )
    })

    const businessRows = [
        {label: 'Company Name', value: selectedSignup.companyName},
        {label: 'Business Address', value: addressToString(constructAddress('businessAddress'))},
        {label: "Terminal Address", value:  addressToString(constructAddress('terminalAddress'))},
    ].map((row) => {
        return (
            <PDFTableRow key={row.label} columns={[row.label, row.value]} fontSize={14} padding={4}/>
        )
    })
 
    return (
        <Document>
            <Page orientation='landscape' size='LETTER' style={{padding: '0.5in', flexDirection: 'column'}}>
                <Text style={{textAlign: "center", marginBottom: 12}}>{selectedSignup.companyName}</Text>
                <View style={{gap: 36}}>
                    <View wrap={false}>
                        <PDFTable columns={2} title='Pricing/Plan Details' headers={['', '']} rows={pricingRows} fontSize={16}/>
                    </View>
                    <View wrap={false}>
                        <PDFTable columns={2} title='Personal Details' headers={['', '']} rows={personalRows} fontSize={16}/>
                    </View>
                    <View wrap={false}>
                        <PDFTable columns={2} title='Business Details ' headers={['', '']} rows={businessRows} fontSize={16}/>
                    </View>
                </View>
            </Page>
        </Document>
        
    )
}