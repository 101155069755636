/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from "react";
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import { Validator, validateGreaterThanZero } from "../../../../../../validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import CustomToolTip from "../../../../../../components/CustomToolTip";
import QuickTable from "../../../../../../components/QuickTable";
import { Button } from "react-bootstrap";
import moment from "moment";
import { closestDateBeforeOrOnDayIndex } from "../../../../payrollTools";

export default function PayrollEmployeeNondiscretionaryBonuses({entry, handleSetWeek, payrollPeriodStartDay}) {

    const removeNondiscretionaryBonus = (id, index) => {
        const newArr = entry.weeks[index].ndBonuses.filter((element) => {
            return element.id !== id;
        });
        handleSetWeek(index, 'ndBonuses', newArr);
    }

    const handleSetNondiscretionaryBonus = (id, index, key, value) => {
        const newArr = Array.from(entry.weeks[index].ndBonuses);
        newArr.find(r => r.id === id)[key] = value;
        handleSetWeek(index, 'ndBonuses', newArr);
    }

    const weekElements = entry.weeks.map((week, index) => {
        const trueWeekStart = moment(closestDateBeforeOrOnDayIndex(entry.periodStart, payrollPeriodStartDay)).add(index, 'weeks').format('YYYY-MM-DD');
        const trueWeekEnd = moment(trueWeekStart).add(6, 'days').format('YYYY-MM-DD');
        const weekStart = trueWeekStart < entry.periodStart ? entry.periodStart : trueWeekStart;
        const weekEnd = trueWeekEnd > entry.periodEnd ? entry.periodEnd : trueWeekEnd;

        const ndBonusRows = week.ndBonuses.map((nd) => {
    
            return (
                <tr key={nd.id}>
                    <td>
                        <InputGroup>
                            <InputGroup.Text style={{padding: '2px 6px 2px 6px'}}>$</InputGroup.Text>
                            <Form.Control isInvalid={!nd.amount || nd.amount <= 0} style={{padding: '2px 6px 2px 6px'}} type='number' min={0} value={nd.amount} placeholder='Amount' onChange={(event) => {handleSetNondiscretionaryBonus(nd.id, index, 'amount', event.target.value)}}/>
                        </InputGroup>
                    </td>
                    <td>
                        <div style={{display: 'flex', alignItems: 'center', gap: 4}}>
                            <Form.Check disabled={!nd.canBeReoccuring} type='switch' checked={nd.isReoccuring} onChange={() => {handleSetNondiscretionaryBonus(nd.id, index, 'isReoccuring', !nd.isReoccuring)}}/>
                            { nd.canBeReoccuring ?
                                <CustomToolTip text={'Next payroll period, this bonus will be automatically added to this employee'}/>
                                :
                                <CustomToolTip text={'This bonus was automatically generated from this employee\'s pay'}/>
                            }
                        </div>
                    </td>
                    <td>
                        <Button style={{color: 'white', padding: '2px 6px 2px 6px', width: '100%'}} onClick={() => removeNondiscretionaryBonus(nd.id, index)}>
                            <FontAwesomeIcon icon={faTrashCan}/>
                        </Button>
                    </td>
                </tr>
            )
        });


        return (
            <React.Fragment key={index}>
                { entry.weeks.length > 1 && week.ndBonuses.length > 0 && 
                    <tr key={index + 'week'}>
                        <td colSpan={4} style={{textAlign: 'center', fontWeight: 'bold'}}>{`Week #${index + 1} (${moment(weekStart).format('MMM D')} - ${moment(weekEnd).format('MMM D')})`}</td>
                    </tr>
                }
                {ndBonusRows}
            </React.Fragment>
        )
    })


    return (
        <QuickTable noMargin title='Nondiscretionary Bonuses' headers={['Amount', 'Reoccurring?', '']} widths={[null, 100, 40]} rows={weekElements} size={'sm'}/>
    )
}