/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { useEffect, useState } from "react";
import { AppGlobals } from "../../App";
import { getCompanies } from "../../services/CompanyServices/CompaniesService";
import LoadingWrapper from "../../components/LoadingWrapper";
import MigrateCompany from "./MigrateCompany";
import DeleteAuthenticationTokens from "./DeleteAuthenticationTokens";
import PopulateRoute from "./PopulateRoute";
import { getAdminToolResources } from "../../services/AdminToolsService";
import MigrateCsa from "./MigrateCsa";
import MigrateOldDocumentRequests from "./MigrateOldDocumentRequests";
import { Card } from "react-bootstrap";
import ApiLog from "./ApiLog/ApiLog";
import CompanyEmployeesList from './CompanyEmployeesList'
import CompanyPtoTransaction from "./CompanyPtoTransaction";
import ConferenceShowcase from "./ConferenceShowcase";

export default function AdminTools({}) {
    const [isLoading, setIsLoading] = useState(false);
    const [companies, setCompanies] = useState([])
    const [csas, setCsas] = useState([]);

    useEffect(()=>{
        loadData();
    }, [])

    async function loadData(){
        setIsLoading(true);
        const response = await getAdminToolResources();
        if(response.status == 200){
            setCompanies(response.companies);
            setCsas(response.csas)
        }else{
            AppGlobals.alert('danger', response.message);
        }
        setIsLoading(false);
    }
    
    return (
        <LoadingWrapper isLoading={isLoading}>
            <div style={{height: '100%', overflowY: 'auto', padding: 12, textAlign: 'start', display: 'flex', flexDirection: 'column', gap: 12}}>
                <CompanyEmployeesList companies={companies}/>
                <ApiLog/>
                <MigrateCompany companies={companies}/>
                <MigrateCsa companies={companies} csas={csas}/>
                <CompanyPtoTransaction companies={companies} csas={csas}/>
                <DeleteAuthenticationTokens/>
                <ConferenceShowcase/>
                <MigrateOldDocumentRequests/>
                {/* <PopulateRoute/> */}
            </div>
        </LoadingWrapper>
    )
}