/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { getResponse, getToken } from "../serviceTools";


export async function getAllDocuments(companyUserIdentifier) {
    const data = {
        type: 'hr',
        target: 'getAllDocuments',
        authToken: getToken(),
        companyUserIdentifier: companyUserIdentifier
    }

    return await getResponse(data);
}

export async function getDocument(documentType, uid) {
    const data = {
        type: 'hr',
        target: 'getDocument',
        authToken: getToken(),
        documentType: documentType,
        uid: uid
    }

    return await getResponse(data);
}

export async function downloadEDDocument(uid, documentType) {
    var data = {
        type: 'hr',
        target: 'downloadEdDocument',
        authToken: getToken(),
        uid: uid,
        documentType: documentType
    }
    return await getResponse(data);
}

export async function downloadDlOrSsn(uid) {
    var data = {
        type: 'hr',
        target: 'downloadDlOrSsn',
        authToken: getToken(),
        uid,
    }
    return await getResponse(data);
}

export async function downloadDocumentRequest(uid) {
    var data = {
        type: 'hr',
        target: 'downloadDocumentRequest',
        authToken: getToken(),
        uid: uid
    }
    return await getResponse(data);
}

export async function deleteEDDocument(uid, documentType) {
    var data = {
        type: 'hr',
        target: 'deleteEdDocument',
        authToken: getToken(),
        uid: uid,
        documentType: documentType
    }
    return await getResponse(data);
}

export async function sendDocumentRequestInvitation(uid, sendTo) {
    const data = {
        type: 'hr',
        target: 'sendDocumentRequestInvitation',
        authToken: getToken(),
        uid: uid,
        sendTo: sendTo
    }

    return await getResponse(data);
}

export async function sendBackEdDocument(documentRequestIdentifier, documentType, message) {
    var data = {
        type: 'hr',
        target: 'sendBackEdDocument',
        authToken: getToken(),
        documentRequestIdentifier: documentRequestIdentifier,
        documentType: documentType,
        message: message,
    }
    return await getResponse(data);
}

export async function sendBackDlOrSsn(documentRequestIdentifier, documentType, message) {
    var data = {
        type: 'hr',
        target: 'sendBackDlOrSsn',
        authToken: getToken(),
        documentRequestIdentifier,
        documentType,
        message
    }
    return await getResponse(data);
}

export async function downloadOldAttachment(uid) {
    var data = {
        type: 'hr',
        target: 'downloadOldAttachment',
        authToken: getToken(),
        uid: uid,
    }
    return await getResponse(data);
}

/////////////////////////
/// ONBOARDING DOCUMENTS
/////////////////////////

export async function downloadDocumentRequestOnboarding(uid) {
    var data = {
        type: 'hr',
        target: 'downloadDocumentRequestOnboarding',
        authToken: getToken(),
        uid: uid
    }
    return await getResponse(data);
}

export async function downloadDocumentRequestDocument(uid, documentType) {
    var data = {
        type: 'hr',
        target: 'downloadDocumentRequestDocument',
        authToken: getToken(),
        uid: uid,
        documentType: documentType
    }
    return await getResponse(data);
}

export async function rejectDocumentRequestDocument(documentRequestIdentifier, documentType, message) {
    var data = {
        type: 'hr',
        target: 'rejectDocumentRequestDocument',
        authToken: getToken(),
        documentRequestIdentifier: documentRequestIdentifier,
        documentType: documentType,
        revisionMessage: message,
    }
    return await getResponse(data);
}

export async function sendDocumentRequestInvitationOnboarding(uid, sendTo) {
    const data = {
        type: 'hr',
        target: 'sendDocumentRequestInvitationOnboarding',
        authToken: getToken(),
        uid: uid,
        sendTo: sendTo
    }

    return await getResponse(data);
}

export async function deleteDocumentRequestDocument(uid, documentType) {
    var data = {
        type: 'hr',
        target: 'deleteDocumentRequestDocument',
        authToken: getToken(),
        uid: uid,
        documentType: documentType
    }
    return await getResponse(data);
}

export async function downloadDocument() {} //relied on by HRTimesheet & Payroll


