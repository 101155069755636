/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Big from "big.js";
import moment from "moment";

export const adminMode = true;

export const payTypeDictionary = {
    0: 'Per Hour',
    1: 'Per Year (non-exempt)',
    2: 'Per Year (exempt)',
}
export const payRateDictionary = {
    ph: 'Hourly Rate',
    pd: 'Daily Rate',
    py: 'Salary',
    pm: 'Pay Per Mile',
    ps: 'Pay Per Stop',
    xd: 'Extra Day Rate'
}
export const wageDictionary = {
    ph: 'Hourly Wages',
    pd: 'Regular Wages',
    py: 'Regular Wages',
    pm: 'Mile Wages',
    ps: 'Stop Wages',
    xd: 'Extra Day Wages'
}

export const unitsWorkedDictionary = {
    ph: 'Hours Worked',
    pd: 'Days Worked',
    pm: 'Miles',
    ps: 'Stops',
    xd: 'Extra Days Worked'
}

export const holidayPayTypeDictionary = {
    ph: 'Per Hour',
    pd: 'Per Day',
    flat: 'Flat Amount',
}

export function getUnitsWorkedTypeString(payType) {
    if (payType == 0) {
        return 'Hours Worked';
    } else { //pd and py
        return 'Days Worked';
    }
}

export const employeeTypeDictionary = {
    0: 'Full-Time',
    1: 'Part-Time'
}

export function validateDecimal(value) {
    return isNaN(parseFloat(value)) ? 0 : parseFloat(value);
}

export function validateInteger(value) {
    return isNaN(parseInt(value)) ? 0 : parseInt(value);
}

export function validateBig(value, defaultValue = new Big('0.0')) {
    try {
        return new Big(value);
    } catch {
        return defaultValue;
    }
}

export function bigToUsd(value) {
    return '$' + value.toFixed(2)
}

export function validateUsd(value) {
    return isNaN(parseFloat(value)) ? 0.0 : parseFloat((parseFloat(value)).toFixed(2));
}

export function toFixedMax(number, decimalPlaces) {
    return parseFloat(parseFloat(number).toFixed(decimalPlaces));
}

export function closestDateBeforeOrOnDayIndex(dateStr, dayIndex) {
    // Parse the input date string to a Date object
    const date = moment(dateStr, 'YYYY-MM-DD');
    
    // Get the current day index (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    const currentDayIndex = date.day(); // getDay() returns 0-6

    // Calculate how many days to subtract to reach the desired day index
    const daysToSubtract = (currentDayIndex - dayIndex + 7) % 7;

    // Subtract the days from the current date
    date.subtract(daysToSubtract);

    return date.format('YYYY-MM-DD')
}