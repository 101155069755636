/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { useEffect, useState } from "react";
import CustomTabs from "../../components/CustomTabs";
import DashboardOld from "./DashboardOld/DashboardOld";
import DashboardTC2 from "./DashbordTC2/DashboardTC2";

export default function Dashboard() {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  return (
    <div className="page-wrapper" style={{ display: "block", padding: 16 }}>
      <CustomTabs
        style={{ display: "flex", flex: 1, marginBottom: 12 }}
        value={selectedTabIndex}
        setValue={setSelectedTabIndex}
        tabs={["Employee Document Feed", "TC 2.0 Document Feed"]}
      />
      {selectedTabIndex === 0 ? <DashboardOld /> : <DashboardTC2 />}
    </div>
  );
}
