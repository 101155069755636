/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { getResponse, getToken } from "./serviceTools";

export async function getDashboard() {
    const data = {
        type: 'dashboard',
        target: 'get',
        authToken: getToken(),
    }

    const response = await getResponse(data);
    return response;
}

export async function dismissDocumentRequest(uid) {
    const data = {
        type: 'dashboard',
        target: 'dismissDocumentRequest',
        authToken: getToken(),
        uid: uid
    }

    const response = await getResponse(data);
    return response;
}

///////////////////////////
/// NEW DASHBOARD FUNCTIONS
///////////////////////////

export async function getDashboardOnboarding(){
    const data = {
        type: "dashboard",
        target: 'getOnboarding',
        authToken: getToken()
    }

    const response = await getResponse(data);
    return response;
}

export async function dismissDocumentRequestOnboarding(uid) {
    const data = {
        type: 'dashboard',
        target: 'dismissDocumentRequestOnboarding',
        authToken: getToken(),
        uid: uid
    }

    const response = await getResponse(data);
    return response;
}