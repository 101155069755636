/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import { usdFormatter } from "../../../../tools";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Container, Card, Dropdown } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import CustomToolTip from "../../../../components/CustomToolTip";
import PayrollEmployeeDeductionsEditor from "./Deductions/PayrollEmployeeDeductionsEditor";
import PayrollEmployeePayEditor from "./Pay/PayEditor/PayrollEmployeePayEditor";
import PayrollEmployeeLoansEditor from "./Loans/PayrollEmployeeLoansEditor";
import { hydratePayrollEntry as hydratePayrollEntryClient } from "../../../../services/PayrollServiceClient";
import { hydratePayrollEntry as hydratePayrollEntryAdmin } from "../../../../services/PayrollServiceMtb";
import { AppGlobals } from "../../../../App.js";
import CustomAlert from "../../../../components/CustomAlert.js";
import PayrollEntry from "../../Models/PayrollEntry.js";
import HumanResourcesTimesheet from "../../../MTBHumanResources/HRTimesheet/HumanResourcesTimesheet";
import HRUserPay from "../../../MTBHumanResources/HRPay/HRUserPay.js";
import HRUserPto from "../../../MTBHumanResources/HRUserPTO/HRUserPto.js";
import HRUserLoan from "../../../MTBHumanResources/HRLoans/HRUserLoan.js";
import { adminMode } from "../../payrollTools.js";

export default function PayrollEmployeeEditor({selectedPayPeriod, handleSetEntry, entry, handleSet, handleProceed, previousEntry, handleAddBonusToAllEntries, handleAddDeductionToAllEntries, payrollPeriodStartDay}) {
    const [modalSwitch, setModalSwitch] = useState('');

    const handleSetWeek = (index, key, value) => {
        const weeks = Array.from(entry.weeks);
        weeks[index][key] = value;
        handleSet(entry.userIdentifier, 'weeks', weeks);
    }

    const hideModal = () => {
        setModalSwitch('');
    }

    const handleSubmit = () => {
        if (entry.status !== 'approved') {
            handleProceed();
        }
        handleSet(entry.userIdentifier, 'status', (entry.status === 'approved' ? 'pending' : 'approved'));
    }

    const handleHydrateEntry = async () => {
        const response = adminMode ? await hydratePayrollEntryAdmin(JSON.stringify(entry)) : await hydratePayrollEntryClient(JSON.stringify(entry));

        if (response.status === 200) {
            handleSetEntry(PayrollEntry.decode(JSON.parse(response.entry)));
            AppGlobals.alert('success', 'Successfully Updated Entry');
        } else {
            AppGlobals.alert('danger', 'Failed to Update Entry');
        }
    }

    return (
        
        <div style={{overflowY: 'scroll', display: 'flex', flexDirection: 'column', gap: 12, flex: 1, padding: 8}}>
            <Card>
                <Card.Body style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'space-between'}}>
                    <Dropdown>
                        <Dropdown.Toggle variant={'outline-primary'}>Tools</Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => {setModalSwitch('timesheet')}}>Timesheet</Dropdown.Item>
                            <Dropdown.Item onClick={() => {setModalSwitch('pay')}}>Pay</Dropdown.Item>
                            <Dropdown.Item onClick={() => {setModalSwitch('pto')}}>PTO</Dropdown.Item>
                            <Dropdown.Item onClick={() => {setModalSwitch('loans')}}>Loans</Dropdown.Item>
                            {/* { previousEntry && 
                                <Dropdown.Item onClick={() => {setModalSwitch('previousPay')}}>Previous Payroll Entry</Dropdown.Item>
                            } */}
                        </Dropdown.Menu>
                    </Dropdown>
                    <h4 style={{margin: 0}}>
                        {`Gross Pay For ${entry.name()}:`} <b>{usdFormatter.format(entry.gross())}</b>
                    </h4>
                    <Button variant={'outline-primary'} style={{fontWeight: 'bold'}} onClick={handleSubmit}>
                        {entry.status === 'approved' ? 'Undo Approval' : 'Approve'}
                    </Button>
                </Card.Body>
            </Card>

            <PayrollEmployeePayEditor 
                entry={entry} 
                handleSet={handleSet} 
                handleSetWeek={handleSetWeek} 
                handleAddBonusToAllEntries={handleAddBonusToAllEntries}
                selectedPayPeriod={selectedPayPeriod}
                payrollPeriodStartDay={payrollPeriodStartDay}
            />
            
            <PayrollEmployeeDeductionsEditor entry={entry} handleSet={handleSet} handleAddDeductionToAllEntries={handleAddDeductionToAllEntries}/>
            { entry.loans.length > 0 && 
                <PayrollEmployeeLoansEditor entry={entry} handleSet={handleSet} selectedPayPeriod={selectedPayPeriod}/>
            }
            <Card>
                <Card.Body>
                    <Container fluid>
                    <div style={{display: 'flex', alignItems:'center', gap: 12}}>
                        <Card.Title>Notes</Card.Title>
                        { previousEntry &&
                            <Button style={{padding: 4, marginBottom: 8}} variant="outline-primary" onClick={() => {handleSet(entry.userIdentifier, 'notes', previousEntry.notes)}}>
                                Import Notes from Previous Payroll Period
                            </Button>
                        }
                        <div style={{display: 'flex', alignItems: 'center', gap: 12, border: '1px solid lightgray', borderRadius: 6, padding: '3px 8px 3px 8px', marginBottom: 8}}>
                            <Form.Check type='switch' label='Reoccurring' checked={entry.reoccuringNotes} onChange={() => {handleSet(entry.userIdentifier, 'reoccuringNotes', !entry.reoccuringNotes)}}/>
                            <CustomToolTip text={'Next payroll period, these notes will be automatically added to this employee'}/>
                        </div>
                    </div>
                    <Form.Control as='textarea' rows={3} value={entry.notes} onChange={(event) => {handleSet(entry.userIdentifier, 'notes', event.target.value)}}/>
                    </Container>
                </Card.Body>
            </Card>

            
            <Modal show={modalSwitch === 'timesheet'} onHide={hideModal} centered size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title>{`Timesheet for ${entry.name()}`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <HumanResourcesTimesheet selectedEmployee={{uid: entry.userIdentifier}} defaultStartDate={entry.periodStart} defaultEndDate={entry.periodEnd} crudCallback={handleHydrateEntry}/>
                </Modal.Body>
            </Modal>
            <Modal show={modalSwitch === 'pay'} onHide={hideModal} centered size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title>{`Pay for ${entry.name()}`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <HRUserPay selectedEmployee={{uid: entry.userIdentifier, pay: {payType: 0}}} crudCallback={handleHydrateEntry}/>
                </Modal.Body>
            </Modal>
            <Modal show={modalSwitch === 'pto'} onHide={hideModal} centered size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title>{`PTO for ${entry.name()}`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <HRUserPto selectedEmployee={{uid: entry.userIdentifier}} defaultStartDate={entry.periodStart} defaultEndDate={entry.periodEnd} crudCallback={handleHydrateEntry}/>
                </Modal.Body>
            </Modal>
            <Modal show={modalSwitch === 'loans'} onHide={hideModal} centered size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title>{`Loans for ${entry.name()}`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <HRUserLoan selectedEmployee={{uid: entry.userIdentifier}} crudCallback={handleHydrateEntry}/>
                </Modal.Body>
            </Modal>
        </div>

    )

}